import Axios from "axios";

// import JwtUtils from "../utils/jwtUtils";
import { apiHost } from "../constants/ApiConstants";
import apiClient from "./ApiClient";

export default class ApiGameAnalytics {
  static async getAnalytics(tourId, page, pageSize, sortColumn) {
    return await apiClient
      .get(`${apiHost}/analytics/${tourId}`, {
        params: {
          page: page,
          pageSize: pageSize,
          sortColumn: sortColumn,
        },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async updateAnalytic(analyticId, body) {
    // const tokens = await JwtUtils.getValidTokens();
    // if (!tokens) {
    //   return { status: null, data: null };
    // }
    // const authHeader = "Bearer " + tokens.accessToken;

    console.log("analytic id ", analyticId);
    console.log("body ", body);

    return await apiClient
      .post(`${apiHost}/analytic/${analyticId}`, body, {
        // headers: { Authorization: authHeader },
      })
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response.status,
            data: error.response.data,
          };
        }

        return errorLog;
      });
  }

  static async getMapTilesRequests(userId, startDate, endDate) {
    return await apiClient
      .get(
        `${apiHost}/analytic/mapTilesRequests/${userId}/${startDate.toISOString()}/${endDate.toISOString()}`,
        {}
      )
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async getMapTilesRequestsTotalForUser(userId) {
    return await apiClient
      .get(`${apiHost}/analytic/mapTilesRequests/${userId}`, {})
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }

  static async getMapTilesRequestsTotalThreeMonth() {
    return await apiClient
      .get(`${apiHost}/analytic/mapTilesRequests/total/threeMonth`, {})
      .then((response) => {
        let responseLog = { status: response.status, data: response.data };

        return responseLog;
      })
      .catch((error) => {
        let errorLog = { status: 500, data: "Undefined error" };
        if (error.response) {
          errorLog = {
            status: error.response?.status,
            data: error.response?.data,
          };
        }

        return errorLog;
      });
  }
}
